// @include font-face(400, 'Roboto');
@include font-face(300, 'VTBGroup');
@include font-face(400, 'VTBGroup');
@include font-face(600, 'VTBGroup');
@include font-face(700, 'VTBGroup');
@include font-face(300, 'VTBGroupCond');
@include font-face(400, 'VTBGroupCond');
@include font-face(600, 'VTBGroupCond');
@include font-face(700, 'VTBGroupCond');
@include font-face(300, 'Roboto');
@include font-face(400, 'Roboto');
@include font-face(700, 'Roboto');